const Paymentlink = () => {
  return (
    <div style={{fontStyle:'arial'}}>
        <div style={{marginLeft:'10%',marginTop:'5%'}}>
            <h3>Device Price List</h3>

            <div style={{display:'flex'}}>
             <div> 
              <p style={{fontSize:17,fontWeight:'bold',color:'black',fontStyle:'arial'}}>Cyclesense Fertility Monitor</p>
             </div>
             <div style={{background:'#191970',marginLeft:10}}>
             <p style={{fontSize:20,fontWeight:'bold',color:'white',fontStyle:'arial'}}>Rs 2399/-</p>
             </div>            
             <p style={{marginLeft:10}}>Including all taxes.</p>
            </div>
            <div style={{display:'flex'}}>
             <div> 
              <p style={{fontSize:17,fontWeight:'bold',color:'black',fontStyle:'arial'}}>OvuPeak</p>
             </div>
             <div style={{background:'#191970',marginLeft:10}}>
             <p style={{fontSize:20,fontWeight:'bold',color:'white',fontStyle:'arial'}}>Rs 499/-</p>
             </div>            
             <p style={{marginLeft:10}}>Including all taxes.</p>
            </div>
            <p style={{marginLeft:10,color:'black'}}>Device stocks are available</p>

            <p style={{marginLeft:10}}>Any help during purchase email "support@symptothermal.life or +91 9742959710"</p>
            
        </div>
        <div style={{display:'list-item'}}>
      <div style={{ marginLeft: "10%", marginTop: "3%" }}>
        <h3>Payment Mode:NEFT/RTGS/NetBanking</h3>
        <p>Bank account details</p>
        <p>A/C No: 05210200001044</p>
        <p>Bank Name: Bank of Baroda</p>
        <p>A/C holder Name: CYCLESENSE HEALTH LLP</p>
        <p>IFSC Code: BARB0STJOHN</p>
        <p>Branch: JOHN NAGAR BRANCH,BENGALURU 560034</p>
      </div>
      <div style={{ marginLeft: "10%", marginTop: "2%" }}>
        <h3>Payment Mode: UPI </h3>
        <p>PhonePe</p>

        <p>Coming soon...</p>
       {/*  <img
            border="0"
            style={{
              marginLeft: 10,
              marginBottom: 10,
              maxWidth: 170,
              maxHeight: 340,
            }}
            alt="screenshot"
            src={require("./images/bar-code.png")}
          /> */}

      </div>
      </div>

    </div>
  );
};

export default Paymentlink;
