import './Cycle.css';

const Cycle = () => {
  function openCity(evt,cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  }


    return (
     /*  <div style={{marginTop:'31%',marginBottom:20}}>
        
    <h2>Anxiety and the Menstrual Cycle</h2>
    <p> It’s normal to experience feelings of stress and nervousness when facing challenges, but in some of us, anxiety strikes frequently and severely and needs to be addressed. But what to do when you feel anxious only a week or days before your period? That can signal hormonal imbalances and reproductive health issues.</p>
    </div>
    */
   <div style={{marginTop:'20%'}}>
    {/* <div className="tab">
    <button className="tablinks" onMouseOver="openCity(event, 'London')">London</button>
    <button className="tablinks" onMouseOver="openCity(event, 'Paris')">Paris</button>
    <button className="tablinks" onMouseOver="openCity(event, 'Tokyo')">Tokyo</button>
  </div> */}
  <div id="London" className="tabcontent">
  <h3>London</h3>
  <p>London is the capital city of England.</p>
</div>

<div id="Paris" className="tabcontent">
  <h3>Paris</h3>
  <p>Paris is the capital of France.</p> 
</div>

<div id="Tokyo" className="tabcontent">
  <h3>Tokyo</h3>
  <p>Tokyo is the capital of Japan.</p>
</div>

<div className="clearfix"></div>

  </div>
    
    )
  };
  
  export default Cycle;