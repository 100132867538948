const Blogs = () => {
    return (
      <div style={{marginTop:'20%',marginBottom:30,marginLeft:5}}>
     <h3>Q: What problem CycleSense App solves?</h3>
    <p>Ans.CycleSense App will predic your next period date, next fertile days,you can record your body symptoms and learn your menstrual cycle management,Fertility content,contraceptive pills reminder,optimal days for conceiving.</p>
    <h3>Q: How to configure my period cycle?</h3>
    <p>Ans.When you launch app ,go to settings screen.You can provide your period details.</p>
    <h3>Q: CycleSense App is Free or Paid?</h3>
    <p>Ans.You can download as a free,then can upgrade to premium version. </p>
    <h3>Q: How to know when any upgrade version is avilable?</h3>
    <p>Ans.In dashboard screen a button name "Check App update" is avilable you can click. </p>
    <h3>Q: What is the use of report screen?</h3>
    <p>Ans.In the report screen, you can generate report of your body past history of symptoms ,sexul activity,wight,temperture,daily medications or contraceptive methods used as PDF format and send to healthcare profesionals.This report is a medical grade report and accepted by healthcare professionals across globe.  </p>
    <h3>Q: How my intimacy data is safe?</h3>
    <p>Ans.Your data will reside in your device. We cansider it is a medical device and your data stays at your smartphone.We dont have user database and we won't ask our user sign-in before use.</p>
    <h3>Q: What are Premium plans?</h3>
    <p>Ans.We have multiple products .Some Apps have onetime purcahse and some Apps only monthly,quartly subscription plan. </p>
    <h3>Q: How to get support if any issues during operation?</h3>
    <p>Ans.You can write email to  support@symptothermal.life .We will respond within 24 hours time period for premium users and for FREE users it may take max one week time.</p>
    </div>
    
    )
  };
  
  export default Blogs;