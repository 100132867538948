
import { Outlet, Link } from "react-router-dom";

const Layout = () => {
  const myStyle = {
    color: "#000000",
    backgroundColor: "lightblue",
    fontFamily: "Sans-Serif",
    
  };
  return (
    <>
    <div style={{height:30,backgroundColor:'#7A1674'}}>
        <p style={{textAlign:'center',color:'white'}}>
         New version # 1.2.36 is available to upgrade without additional price.
        </p>
      {/* <img style={{width:'50%',marginLeft:'auto',marginRight:'auto',display:'block'}} border="0" alt="screenshot" src={require('./images/logo.png')} /> */}
      </div>
    <div style={{marginBottom:10,height:40,width:'auto'}}>
      <nav style={{backgroundColor: "white"}}>
      
      <div style={{marginBottom:20}}>
      <a href="/" style={{marginLeft:'5%',color:'#770274',fontSize:25,fontWeight:'bold'}}>Cycle sense</a> 
      <a href="/home" style={{marginLeft:'5%'}}>How does device works?</a> 
      <a href="/faq" style={{marginLeft:'5%'}}>FAQ</a>     
      <a href="/r&d" style={{marginLeft:'5%'}}>News</a> 
     {/*  <a href="/cycle" style={{marginLeft:'5%'}}>Menstrual Cycle</a>  */} 
      <a href="/privacy" style={{marginLeft:'5%'}}>Privacy</a>
      <a href="/terms&conditions" style={{marginLeft:'5%'}}>Terms&Conditions</a>
      <Link to="/contact" style={{marginLeft:'5%'}}>Contact</Link>
      {/* <Link to="/payment" style={{marginLeft:'5%'}}>Payment</Link> */}
     
      </div>
      <div style={{marginBottom:10,height:10}}></div>
      
       
      </nav>
      </div>
      <Outlet />
    </>
  )
};

export default Layout;