const Research = () => {
        return (
        <div style={{marginTop:'10%'}}>
            <div style={{ marginLeft: "3%", marginTop: "5%" }}>
            <img
              border="0"
              alt="website"
              src={require("./images/who-icon.png")}
              
            />
          <a  href="https://www.who.int/news-room/fact-sheets/detail/family-planning-contraception">
            
           1. Family planning/contraception methods
          </a>

        </div>
        <div style={{ marginLeft: "3%", marginTop: "5%" }}>
            <img
              border="0"
              alt="website"
              src={require("./images/who-icon.png")}
              
            />
          <a  href="https://www.who.int/news/item/22-06-2022-promoting-contraception-choice-for-every-individual">
            
           2. Promoting contraception choice for every individual
          </a>

        </div>
        </div>
    )
  };
  
  
  export default Research;