
const Content1="Privacy Policy Symptothermal Corporation built the CycleSense Period Tracker as an Ad Supported app. This SERVICE is provided by Symptothermal Corporation at no cost and is intended for use as is."
const Content2="This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service."
const Content3="If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy."
const Content4="The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at CycleSense unless otherwise defined in this Privacy Policy."
const Content5="For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to It will collect women's period duration; period cycle length and period start date. It will also ask user to note their health condition like,mood,flow type, body temperture,weight .All the personal informations are stored on their device .The information that we request will be retained by your device and used as described in this privacy policy."
const Content6="The app does use third-party services that may collect information used to identify you."
const Content7="Link to the privacy policy of third-party service providers used by the app"
const Content8="We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics."
const Content9="Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory."
const Content10="This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service."
const Content11="We may employ third-party companies and individuals due to the following reasons:"
const Content12="We want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose."
const Content13="We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security."
const Content14_linkToOtherSite="This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services."
const Content15_ChildrensPrivacy="These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13 years of age. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do the necessary actions."
const Content16_ChangesToThisPrivacyPolicy="We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page."
const Content16_ChangesToThisPrivacyPolicy_p2="This policy is effective as of 2023-05-05"
const Content17_ContactUs="If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at Email : support@symptothermal.life,202,Ittangur Road, Bangalore, India."


const Privacy = () => {
    return (
    <div style={{marginTop:'10%',marginBottom:2}}>
    <h2>Privacy Policy</h2>
    <p>{Content1}</p>
    <p>{Content2}</p>
    <p>{Content3}</p>
    <p>{Content4}</p>
    <h2>Information Collection and Use</h2>
    <p>{Content5}</p>
    <p>{Content6}</p>
    <p>{Content7}</p>
    <h2>Log Data</h2>
    <p>{Content8}</p>
    <h2>Cookies</h2>
    <p>{Content9}</p>
    <p>{Content10}</p>
    <h2>Service Providers</h2>
    <p>{Content11}</p>
    <ul>
       <li>
       To facilitate our Service;
       </li> 
       <li>
       To provide the Service on our behalf;
       </li> 
       <li>
       To perform Service-related services; or
       </li>
       <li>
       To assist us in analyzing how our Service is used.
       </li>
    </ul>
    <p>{Content12}</p>
    <h2>Security</h2>
    <p>{Content13}</p>
    <h2>Links to Other Sites</h2>
    <p>{Content14_linkToOtherSite}</p>
    <h2>Children’s Privacy</h2>
    <p>{Content15_ChildrensPrivacy}</p>
    <h2>Changes to This Privacy Policy</h2>
    <p>{Content16_ChangesToThisPrivacyPolicy}</p>
    <p>{Content16_ChangesToThisPrivacyPolicy_p2}</p>    
    <h2 >Contact Us</h2>
    <p>{Content17_ContactUs}</p> 
    </div>
    )
  };
  
  export default Privacy;