
import { useEffect } from "react";

const Cyclesense = () => {
 

  const downloadPdf = async () => {
    // Replace 'path_to_your_pdf_file.pdf' with the actual path to your PDF file
    const pdfUrl = 'cyclesense.pdf';
   // const pdfUrl = '/CycleSense.apk'; // apk download

    // Create a temporary anchor element
    const anchor = document.createElement('a');
    anchor.href = pdfUrl;
    //anchor.target = '_blank';
    anchor.download = 'cyclesense.pdf'; // Set the desired filename for the downloaded PDF
    //anchor.download = 'CycleSense.apk'; // Apk download temporary 
    //anchor.setAttribute('download', 'CycleSense.apk');
    //anchor.setAttribute('rel', 'noopener noreferrer');
    //anchor.setAttribute('hreflang', 'get'); 
    // Trigger the click event to start the download
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const downloadCycleSenseApk = async () => {
    // Replace 'path_to_your_pdf_file.pdf' with the actual path to your PDF file
   // const pdfUrl = 'cyclesense.pdf';
   const pdfUrl = 'CycleSense.apk'; // apk download

    // Create a temporary anchor element
    const anchor = document.createElement('a');
     anchor.href = pdfUrl;
    //anchor.target = '_blank';
    //anchor.download = 'cyclesense.pdf'; // Set the desired filename for the downloaded PDF
    anchor.download = 'CycleSense.apk'; // Apk download temporary 
    //anchor.setAttribute('download', 'CycleSense.apk');
    //anchor.setAttribute('rel', 'noopener noreferrer');
    //anchor.setAttribute('hreflang', 'get'); 
    // Trigger the click event to start the download
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const downloadOvuPeakApk = async () => {
    // Replace 'path_to_your_pdf_file.pdf' with the actual path to your PDF file
   // const pdfUrl = 'cyclesense.pdf';
   const pdfUrl = '/OvuPeak.apk'; // apk download

    // Create a temporary anchor element
    const anchor = document.createElement('a');
    anchor.href = pdfUrl;
    //anchor.target = '_blank';
    //anchor.download = 'cyclesense.pdf'; // Set the desired filename for the downloaded PDF
    anchor.download = 'OvuPeak.apk'; // Apk download temporary 
    //anchor.setAttribute('download', 'CycleSense.apk');
    //anchor.setAttribute('rel', 'noopener noreferrer');
    //anchor.setAttribute('hreflang', 'get'); 
    // Trigger the click event to start the download
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };


  /* useEffect({

  },[]) */
  const heroComponent=()=>{

    return(
      <div style={{width:'100%',display:'flex'}}>
        
         <div>
         {/* <span>
            <img
              border="0"
              alt="screenshot"
              width="20%"
              height="auto"
              src={require("./images/galaxy-watch.png")}
            />
            </span>
          */}  
         
          <img  src={require("./images/device1.png")} alt="hero" style={{
         left: 0,
        width: 'auto',
        height: 'auto',
       
      }} />
     
      
       <img  src={require("./images/device2.png")} alt="hero" style={{
             
        width: 'auto',
        height: 'auto',
       
      }} />
      
      <p style={{fontWeight:'bold',fontSize:20,color:'purple',marginLeft:'30%'}}>
        A Cloud based Ovulation detection technology <br></br>
        India FDA registered<br></br>
        Highly secure data<br></br>
        2 Journals published so far<br></br>
        Tested and Trusted by 5000 women
      </p>
      <p style={{fontWeight:'bold',fontSize:40,color:'green',marginLeft:'10%',fontFamily:'sans-serif'}}>
        A Super App for Women's Fertility Journey 
      </p>
      
      
      </div>
      
      
       {/* <img style={{marginTop:'-10%',marginLeft:'80%'}} src={require("./images/graph.jpg")} /> */}
       
      {/*  <p style={{marginTop:'-20%',marginLeft:'60%',color:'black',fontSize:17,fontWeight:'bold'}}>
        
       Easy to use app<br></br>
       Cloud based solution<br></br>
       Less expensive and non-disposable<br></br>
       99% accuracy<br></br> 
       Instructions are clear and simple

        </p> */}
        
         
        
           
          
          
      </div>
    )
  }
  return (
    <div style={{ marginTop: "2%", marginBottom: 1, marginLeft: 20 }}>
      <div>
        <div style={{marginBottom:40}}>
          {heroComponent()}
        </div>
        
        {/* <h3>
          About CycleSense Health</h3> */}
          {/* <img
              border="0"
              alt="screenshot"
              width="20%"
              height="auto"
              src={require("./images/logo-new.png")}
            /> */}
          

         {/*  CycleSense by Symptothermal is a women's health and wellness related AI
          based mobile software which are avilable on Play store and App store.
          Using science and data AI will predict women's period cycles and
          ovulation and overal women's health management.Symptothermal's
          overall Menstrual helath management report is a medical grade report
          which is accepted by many healthcare professions. */}
        
        <div style={{width:'80%',display:'flex'}}>
        <img             
              alt="screenshot"
              src={require("./images/nasscom.png")}
              style={{width:'auto',height:'80%'}}
            />
        <p style={{fontSize:17}}>
          {/* <p>
          CycleSense is the fertility tracker and cycle monitor software on the market which can 
          accurately predict and confirm your ovulation using hormonal driven current cycle temperature data.
          The algorithim predicts control of conception or conceive and recommend to the users.

          </p> */}
          <p>
          CycleSense health R&D team developed a cloud-based ovulation 
detection software, which can identify and narrow down fertile days in a cycle and 
display user as a graphical presentaion  to easily read the ovulation results from the comfort of their 
home.
          </p>
          <p>
          Generally, there are 8 fertile days in a cycle.CycleSense ovulation 
software inform egg’s live movement and inform the user status of the egg.
It is a non-invasive device  and available to user as subscription model.

 
          </p>
           </p>
        </div>        

      
      </div> 
     {/*  <div style={{display:'flex',justifyContent:'space-around'}}>
       <div>
        <h2>For Getting Pregnant</h2>
        </div>
       <div><h2>For Avoiding Pregnant</h2></div>
       <div><h2>For PCOS</h2></div>
      </div> */}

      
      <div>
       {/*  <div style={{marginLeft:'10%'}}>
          <h3 >Ovulation Rapid Test Kit  Vs   CycleSense  </h3>
        </div> */}
       {/*  <div style={{display:'flex'}}>
          <div style={{marginLeft:'10%'}}>
          <img             
              alt="screenshot"
              src={require("./images/ovulation-img.png")}
              style={{width:80,height:250}}
            />
          </div>
          <div style={{marginTop:50}}>
          =
          </div>
          <div style={{marginLeft:20}}>
          <img             
              alt="screenshot"
              src={require("./images/logo-icon.png")}
              style={{width:'80%'}}
              
            />

          </div>

          

        </div> */}
        {/* <h3 >Ovulation kits required user need to drop urine on test kit early morning and use one time. Where as Device digital no urine required,just a smartphone  </h3>
        <p> Other benifits using Device digital ,you can record your symptomes,notes,Bleeding and many more.Generate report as PDF format and share with whatsapp,email to healthcare professions</p> */}
       {/* <div style={{backgroundColor:'lightgray'}}>
        <h4>CycleSense Integrate with healthcare systems.</h4>
        <img             
              alt="screenshot"
              src={require("./images/core.png")}
              style={{width:'82%',height:"auto"}}
              
            />
            </div> */}
            {/* <div >
              <h4>Typical Menstrual Cycles </h4>
              <img             
              alt="screenshot"
              src={require("./images/device-output.png")}
              style={{width:'74%',height:"auto"}}
              
            />
            </div> */}

        
        <div style={{background:'#F9F7ED',margin:10,width:'100%',height:400}}>
          <h2 style={{marginLeft:'20%'}}>How does CycleSense works for users?</h2>
          {/* <p style={{width:"100%",margin:10}}>
          CycleSense ovulation software is a class 2 medical grade device software.BBT Thermometer  will send temerature reading using BLE protocol to CycleSense app to gain valuable insights into your ovulation cycles.
          </p> */}
          <p style={{margin:10,fontWeight:'normal',fontSize:20}}>Only 3 easy steps,anyone can learn easily within few hours.</p>
          <div style={{display:'flex',justifyContent:'space-around',marginTop:50,marginLeft:10}}>
          <p style={{fontSize:20,fontWeight:'bold'}}>
            1. Download CycleSense App 
          </p>
          <p style={{fontSize:20,fontWeight:'bold'}}>
            2. Record your Basal Body temerature(Early morning)
          </p>
          <p style={{fontSize:20,fontWeight:'bold'}}>
            3. Check your CycleSense App valuable Insights
          </p>

          

          </div>
         {/*  <p style={{ marginLeft: "1%", marginTop: "5%" }}>
            
          <a href="https://play.google.com/store/apps/details?id=com.rasalabs.lily.myperiodcalendar">
            <img
              border="0"
              alt="website"
              src={require("./images/play-store-logo.png")}
            />
          </a>
        </p> */}
          
        </div>
        <h2>How to use CycleSense?</h2>
        <p style={{fontSize:20,fontWeight:'normal'}}>
          We will provide Instruction  materials you have to download ,few cycles our support team will help how user records data.
          After user self sufficient, we will leave to you .  
        </p>
        <p style={{fontSize:20,fontWeight:'normal'}}>
         1. Buy a digital thermometer nearby farmacy or order online. 
        </p>
        <p style={{fontSize:20,fontWeight:'normal'}}>
         2. Download CycleSense App on Playstore. 
        </p>

        <p style={{fontSize:20,fontWeight:'normal'}}>
          3. Measure your temerature and enter into app.
          
        </p>
        {/* <p>
        <img
            border="0"
            alt="screenshot"
            src={require("./images/screen-settings.jpg")}
            style={{height:'auto',width:"60%"}}
          />
        </p> */}
         {/* <p>
         
          2. If you know your cycle length like 28 or 29 or 31 days.Adjust with + and - button  and click save button.
          Below screen describe details <br></br>
          Today means day of the week like Monday,Tuesday.. ,your cycle day today ,which is primary information.<br></br>
          
          <h3>How to read your result?</h3>
          A green scale display ,your fertile status <br></br>
          <p style={{color:'green',fontSize:20,fontWeight:'bold'}}>Green colour indicator - Today you are safe for sex .</p>
          <p style={{color:'red',fontSize:20,fontWeight:'bold'}}>Red colour indicator - Today you need protection for sex.</p>
          Next line information is what is the name of the phase of the cycle .If want to to know more about name of the 
          cycle ,click ? button .<br></br>
          In the same line next is "Today's Tip" button .If you click it will give some diet tips of the day and some small excerise to do which help and relax.<br></br>
          Next line info is cycle days your are and next signals either green or yellow(for signal notation it is red day).It will give weekly signals before 2 days and today and next four days.You can scroll left and right.
        </p>  */}
        <div>
        <h2>How to read device result?</h2>
        
        <h4>Device Indicator</h4>
        <h4>Green Ring   -<span>  Safe Day(You are Not fertile)</span></h4>
         <h4>Yellow Ring   -<span style={{marginLeft:5}}>  Use Protection (You are fertile) </span></h4>
         
         {/* <p>Along with you have to enter your BBT in the green days and check temerature raises or not</p>
         <p>Along with you have to check your cervical mucus in the green days and check cervical fluid raw egg white,streachy </p>
          <p> What is BBT, what is cervical fluid ? refer inside app content</p> */}
        </div>

        <p>
        <img
            border="0"
            alt="screenshot"
            src={require("./images/indicator.gif")}
            style={{height:'auto',width:"60%"}}
            
          />
          {/* <img
            border="0"
            alt="screenshot"
            src={require("./images/safe2.png")}
            style={{height:'auto',width:"60%"}}
            
          />
           <img
            border="0"
            alt="screenshot"
            src={require("./images/protection1.png")}
            style={{height:'auto',width:"60%"}}
            
          />
           <img
            border="0"
            alt="screenshot"
            src={require("./images/protection2.png")}
            style={{height:'auto',width:"60%"}}
            
          /> */}
          
          
        </p>
       
       
       {/*  <p>
        3.User download instruction PDF how to use this application.It required some shorts of training atleast 2 cycles and our instructor will check and support if it is
         correctly user charting and identifying cervical mucus or not.<br></br>For effectiveness of process user has to train on Natural Family planning method to use this app.
         </p>
         <div style={{display:'flex'}}>
          <div>
          <img border="0" alt="screenshot" src={require("./images/bbt-chart.png")} />
          </div>
          <div style={{margin:5}}>
          <h4>BBT chart to identify ovulation</h4>
          </div>
        </div>

        <div>
          <h3>User has to read instructions to get beter result out of it.</h3>
          <p>From buttom tab ,Tap Insights - Education- Content</p>
        <img border="0"
        alt="screenshot" 
        src={require("./images/q&a.jpg")} 
        style={{height:'auto',width:"60%"}}
        />
        </div> */}
      </div>
      {/* <div style={{ display: "flex" }}>
        <div>
          <img border="0" alt="screenshot" src={require("./images/bbt.png")} />
        </div>
        <div>
          <img
            border="0"
            style={{ marginLeft: 10, marginBottom: 10 }}
            alt="screenshot"
            src={require("./images/report.png")}
          />
        </div>
      </div> */}
      {/* <div style={{ display: "flex" }}>
        <div>
          <img
            border="0"
            style={{
              marginLeft: 10,
              marginBottom: 10,
              maxWidth: 170,
              maxHeight: 340,
            }}
            alt="screenshot"
            src={require("./images/pill.png")}
          />
        </div>
        <div>
          <img
            border="0"
            style={{
              marginLeft: 10,
              marginBottom: 10,
              maxWidth: 170,
              maxHeight: 340,
            }}
            alt="screenshot"
            src={require("./images/note.png")}
          />
        </div>
      </div> */}

      <div style={{display:'flex',justifyContent:'flex-start'}}>
        <h3 style={{marginTop:40}}> To download CycleSense ,Scan this barcode </h3>
      <img
            border="0"
            style={{
              marginLeft: 10,
              marginBottom: 10,
              maxWidth: 170,
              maxHeight: 340,
            }}
            alt="screenshot"
            src={require("./images/bar-code.png")}
          />
      </div>
      <div style={{display:'flex',}}>
      <h3 style={{marginTop:20}}>Download CycleSense from play store  </h3> 
      <a href="https://play.google.com/store/apps/details?id=com.rasalabs.lily.myperiodcalendar">
<img border="0" alt="website" src={require('./images/play-store-logo.png')} />
</a>
      </div>
      <div style={{marginTop:20}}>
        <h3 style={{color:'#8e44ad'}}>2.Mobile software name: OvuPeak(Detect your ovulation at home using thermometer)</h3>
        <p>Device intend is to test confirmed ovulation in a cycle.Instructions are provided in the product manual page.</p>
      </div>
      <div style={{marginTop:5,display:'flex'}}>
         
            <img
              border="0"
              alt="screenshot"
              width="auto"
              height="768"
              src={require("./images/negative.png")}
            />
             <img
              border="3"
              alt="screenshot"
              width="auto"
              height="768"
              src={require("./images/positive.png")}
            />
            <img
              border="3"
              alt="screenshot"
              width="auto"
              height="768"
              src={require("./images/marked.png")}
            />
             <img
              border="3"
              alt="screenshot"
              width="auto"
              height="768"
              src={require("./images/testkit-manual1.png")}
            />
      </div>
      <div style={{display:'flex',marginTop:20}}>
      <h3 style={{marginTop:20}}>Download OvuPeak from play store  </h3> 
      <a href="https://play.google.com/store/apps/details?id=com.map.cyclesense.safedays">
        <img border="0" alt="website" src={require('./images/play-store-logo.png')} />
        </a>
      </div>
      <div>
      <h3>OvuPeak Device Testimonials </h3>
      </div>
      <div style={{marginTop:20,display:'flex'}}>
       
       <div style={{background:"gray"}}>
       <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
            <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
            <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
             <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
       <p style={{margin:7,color:'white'}}>
        "I tried many period tracker apps<br></br>to predict my ovulation day but <br></br>none of them are accurate.<br></br>One of my friend told me to use OvuPeak."
       </p>
       
       <p style={{margin:7,color:'white'}}>
        A Play Store user.
       </p>
      
       </div>
       
       <div style={{background:"gray",marginLeft:10}}>
       <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
            <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
            <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
       <p style={{margin:7,color:'white'}}>
        "I used Test Kits to detect my Ovulation <br></br>day but many Test strips gave me invalid results.<br></br>I was looking digital format of Ovulation detection ,<br></br>so downloaded OvuPeak after that I never look back"

       </p>
       <p style={{margin:7,color:'white'}}>
        A Play Store user.
       </p>
       </div>
       <div style={{background:"gray",marginLeft:10}}>
       <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
            <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
            <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
            <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
            <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
       <p style={{margin:7,color:'white'}}>
        "We couple working as Police Job and shift works<br></br>For us requirment was to identify Peak <br></br>day and try baby.We purchased OvuPeak and <br></br>dont belive first test cycle I got pregnant.<br></br>Best part is their support was too good.<br></br>They provided hints to get best result out of the device."

       </p>
       <p style={{margin:7,color:'white'}}>
       A Play Store user.
       </p>
       </div>
       

      </div>
      

      
      
    </div>
  );
};

export default Cyclesense;
