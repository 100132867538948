import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Layout'
import Home from './Home';
import Blogs from "./Blogs";
import Privacy from './Privacy';
import TermsAndConditions from './Terms&Conditions';
import Contact from './Contact';
import Cycle from './Cycle';
import Research from './Research';
import Cyclesense from './Cyclesense';
import Paymentlink from './Paymentlink';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
        <Route index element={<Cyclesense/>} /> 
          <Route path="home" element={<Home />} />
          <Route path="cycle" element={<Cycle/>} />
          <Route path="faq" element={<Blogs />} />
          <Route path="privacy" element={<Privacy/>} />
          <Route path="terms&conditions" element={<TermsAndConditions/>} />
          <Route path="contact" element={<Contact/>} /> 
          <Route path="r&d" element={<Research/>} /> 
          <Route path="payment" element={<Paymentlink/>} /> 
                   
        </Route>
       
      </Routes>
    </BrowserRouter> 
    
  );
}

export default App;
