
import { useEffect } from "react";

const Home = () => {
  

  const downloadPdf = async () => {
    // Replace 'path_to_your_pdf_file.pdf' with the actual path to your PDF file
    const pdfUrl = 'cyclesense.pdf';
    //const pdfUrl = 'CycleSense.apk'; // for apk file

    // Create a temporary anchor element
    const anchor = document.createElement('a');
    anchor.href = pdfUrl;
    //anchor.target = '_blank';
    anchor.download = 'cyclesense.pdf'; // Set the desired filename for the downloaded PDF
    //anchor.download = 'CycleSense.apk'; // for apk file
    //anchor.setAttribute('download', 'CycleSense.apk');

    // Trigger the click event to start the download
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  /* useEffect({

  },[]) */
  const heroComponent=()=>{

    return(
      <div >
         <p style={{ marginLeft:1}}>
         <span>
            <img
              border="0"
              alt="screenshot"
              width="20%"
              height="auto"
              src={require("./images/galaxy-watch.png")}
            />
            </span>
           
            <img
              border="0"
              alt="screenshot"
              width="60%"
              height="auto"
              src={require("./images/hero-new.png")}
            />
           
          </p>
          
      </div>
    )
  }
  return (
    <div style={{ marginTop: "10%", marginBottom: 10, marginLeft: 20 }}>
      {/* <div>
               

      
      </div>  */}

     
      <div>
       {/*  <div style={{marginLeft:'10%'}}>
          <h3 >Ovulation Rapid Test Kit  Vs   CycleSense  </h3>
        </div> */}
       {/*  <div style={{display:'flex'}}>
          <div style={{marginLeft:'10%'}}>
          <img             
              alt="screenshot"
              src={require("./images/ovulation-img.png")}
              style={{width:80,height:250}}
            />
          </div>
          <div style={{marginTop:50}}>
          =
          </div>
          <div style={{marginLeft:20}}>
          <img             
              alt="screenshot"
              src={require("./images/logo-icon.png")}
              style={{width:'80%'}}
              
            />

          </div>

          

        </div> */}
        {/* <h3 >Ovulation kits required user need to drop urine on test kit early morning and use one time. Where as Device digital no urine required,just a smartphone  </h3>
        <p> Other benifits using Device digital ,you can record your symptomes,notes,Bleeding and many more.Generate report as PDF format and share with whatsapp,email to healthcare professions</p> */}
       
       {/* <div style={{backgroundColor:'lightgray'}}>
        <h4>CycleSense product architecture</h4>
        <img             
              alt="screenshot"
              src={require("./images/core.png")}
              style={{width:'82%',height:"auto"}}
              
            />
            </div> */}
            {/* <div >
              <h4>Typical Menstrual Cycles </h4>
              <img             
              alt="screenshot"
              src={require("./images/device-output.png")}
              style={{width:'74%',height:"auto"}}
              
            />
            </div> */}

        
        <div style={{background:'#F2F3F4',margin:10,width:'100%',height:'auto'}}>
                   
          <h3 style={{fontSize:20 ,textAlign:'center',color:'#770274'}}>How to use Cycle Sense </h3>
         
          <div style={{display:'flex',flexDirection:'row'}}>
          
          <div style={{marginLeft:'10%',fontSize:17,fontWeight:'bold',marginBottom:10,color:'#770274'}}>
            1. Measure your temperature
          </div>
         
         
          <div style={{marginLeft:'10%',fontSize:17,fontWeight:'bold',color:'#770274'}}>2. Open Cycle Sense App</div>
         
          <div style={{marginLeft:'10%',fontSize:17,fontWeight:'bold',color:'#770274'}}>
           3. Confirm your fertility status day
          </div>

          </div>
          <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
          
          <div style={{width:'auto',marginLeft:'5%'}}>
          <img
            border="0"
            alt="screenshot"
            src={require("./images/step1.jpg")}
           
          />
          </div>
         
          <div style={{width:'auto'}}>
          <img
            border="0"
            alt="screenshot"
            src={require("./images/step2.jpg")}
           
          />
          </div>
         
         
          <div style={{width:'auto',marginLeft:10}}>
          <img
            border="0"
            alt="screenshot"
            src={require("./images/step4.png")}
           
          />
          </div>

          </div>

          <div style={{display:'flex',flexDirection:'row'}}>
          
          <div style={{marginLeft:'10%',fontSize:17,marginBottom:10}}>
            Measure your early morning temperature
          </div>
         
         
          <div style={{marginLeft:'10%',fontSize:17}}>
            Enter your temperature </div>
         
          <div style={{marginLeft:'10%',fontSize:17}}>
           Within a few seconds ,algorithim display green day(not fertile) or yellow day(fertile)
           <p>Green day means your are safe for unprotected sex.</p>
           <p>Yellow day means you need protection for intercourse </p>
          </div>

          </div>
        
          
        </div>
        

       

        {/* <p>
        <img
            border="0"
            alt="screenshot"
            src={require("./images/screen-dashboard-green.jpg")}
            style={{height:'auto',width:"60%"}}
            
          />
          
          
        </p> */}
             
      </div>
     
      <h3 style={{textAlign:'center',color:'#770274'}}> Inside of the CycleSense App</h3>
     
     
      <div style={{display:'flex',padding:7,justifyContent:'space-evenly'}}>
      <div style={{height:'auto',borderWidth:7}}>
       
      <img
              border="0"
              alt="screenshot"
              width="auto"
              height="600"
              src={require("./images/redday.png")}
            />
             <h4>Know your fertile status</h4>
             <p>Know your Safe day <br></br>or Use protection day </p>
      </div>
      <div style={{height:'auto'}}>
      <img
              border="0"
              alt="screenshot"
              width="auto"
              height="600"
              src={require("./images/diet.png")}
            />
      <h3>See your diet tips from our certified nutritionist</h3>
      <p>Our inhouse nutritionist always do R&D on diet </p>
      </div>

      <div style={{height:'auto'}}>
      <img
              border="0"
              alt="screenshot"
              width="auto"
              height="600"
              src={require("./images/track.png")}
            />
             <p style={{fontWeight:'bold',fontSize:17,color:'black'}}>Learn your body</p>
             <p>Monitor your symptomes<br></br>learn more about your body</p>
      </div>

      </div>
      
     
      <div style={{display:'flex',justifyContent:'flex-start'}}>
        <h3 style={{marginTop:40}}> To download CycleSense ,Scan this barcode </h3>
      <img
            border="0"
            style={{
              marginLeft: 10,
              marginBottom: 10,
              maxWidth: 170,
              maxHeight: 340,
            }}
            alt="screenshot"
            src={require("./images/bar-code.png")}
          />
      </div>

      <div style={{ backgroundColor: "#38936E", width: "90%", height:'auto' }}>
        <h1
          style={{
            color: "white",
            fontSize: 32,
            fontFamily: "Arial",
            marginLeft: 100,
          }}
        >
         
        </h1>
       {/*  <p
          style={{
            color: "white",
            fontSize: 20,
            fontFamily: "Arial",
            marginLeft: 50,
            marginTop:10

          }}
        >
          CycleSense and other line of products  
        </p> */}

        {/* <div style={{ display: "flex" }}>
          <p style={{ marginLeft: "10%" }}>
            <img
              border="0"
              alt="screenshot"
              width="auto"
              height="768"
              src={require("./images/01-today.gif")}
            />
          
          </p>    
                             
        </div> */}

        {/* <div style={{ display: "flex" }}>
          <table style={{ marginLeft: 11, fontSize: 19,color: "white",fontFamily:'arial' }}>
          
            <tr>
              <td>App Name: CycleSense</td> 
            </tr>
            <tr>
              <td >Price:</td>
            </tr>
          </table>
          <table style={{ marginLeft: 34, fontSize: 19,color: "white",fontFamily:'arial' }}>
            <tr>
              <td>App Name: Best Day</td><span>&#169;</span>
            </tr>
            <tr>
              <td>Price: $1/month</td>
            </tr>
          </table>
          <table style={{ marginLeft: 20, fontSize: 19,color: "white",fontFamily:'arial' }}>
            <tr>
              <td>App Name: Safe Day</td><span>&#169;</span>
            </tr>
            <tr>
              <td>Price: $1/month</td>
            </tr>
          </table>
        </div>
 */}
 <div style={{marginTop:20,display:'flex'}}>
       
       <div style={{background:"gray"}}>
       <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
            <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
            <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
             <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
       <p style={{margin:7,color:'white'}}>
        "I tried many period tracker apps<br></br>to avoid pregnancy naturally  but <br></br>none of them are accurate.<br></br>One of my friend told me to use CycleSense fertility app .This app is FDA registered and regulated by FDA"
       </p>
       
       <p style={{margin:7,color:'white'}}>
        A Play Store user.
       </p>
      
       </div>
       
       <div style={{background:"gray",marginLeft:10}}>
       <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
            <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
            <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
       <p style={{margin:7,color:'white'}}>
        "I used to use Calendar method to avoid my pregnancy .<br></br> Due to irregularity of my period ,someday my ovulation on 22 day.Finally I install on Play store.Best part of the app is no registration required.They use body temp data and analysing and displaying very nice way,I love it."

       </p>
       <p style={{margin:7,color:'white'}}>
        A Play Store user.
       </p>
       </div>
       <div style={{background:"gray",marginLeft:10}}>
       <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
            <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
            <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
            <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
            <img
              
              alt="star icon"
              width="auto"
              src={require("./images/icon-star-48.png")}
            />
       <p style={{margin:7,color:'white'}}>
        "It is very simple app does what we couple need.No ask money again took simple monthly subscription and use all inside app"

       </p>
       <p style={{margin:7,color:'white'}}>
       A Play Store user.
       </p>
       </div>
       

      </div>

        <div style={{margin:10}}>
          <div >
          Download CycleSense App product manual
          </div>
      <button onClick={downloadPdf}>Download</button>
      </div>
        
          
        

        <p style={{ marginLeft: "10%", marginTop: "5%" }}>
          <a href="https://play.google.com/store/apps/details?id=com.rasalabs.lily.myperiodcalendar">
            <img
              border="0"
              alt="website"
              src={require("./images/play-store-logo.png")}
            />
          </a>
        </p>
      </div>
    </div>
  );
};

export default Home;
