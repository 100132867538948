
const Contact = () => {
    return (
        <div style={{marginTop:'15%'}}>
            <div style={{display:'flex',justifyContent:'space-around'}}>
            <img
            border="0"
            style={{
              marginLeft: 10,
              marginBottom: 10,
              width:'auto'
             
            }}
            alt="screenshot"
            src={require("./images/dev-team.png")}
          />
           <img
            border="0"
            style={{
              marginLeft: 10,
              marginBottom: 10,
              width:'auto'
             
            }}
            alt="screenshot"
            src={require("./images/sales-team.png")}
          />
            </div>
           
            <div style={{display: "flex",margin:10}}>
        <div >
    <h2 style={{textAlign:'left'}}>Registered Office</h2>
    <p style={{textAlign:'left'}}> 
    <p style={{fontWeight:'bold'}}>India,Bangalore</p>
    
    202,Ittangur Road
    <br></br>
    Sarjapura,Bangalore
    <br></br>
    Pin:562125,India <br></br>
    Email: support@symptothermal.life<br></br>
    <br></br>
    URL  https://symptothermal.life 
    </p>
    </div>
    <div style={{marginLeft:'25%'}}>
    <h3 >Branch Office</h3>
    <p style={{fontWeight:'bold',marginLeft:30}}>India,Pune</p>
    Sales office Opening shortly ...
    </div>
    <div style={{marginLeft:'20%'}}>
    <h3 >Branch office</h3>
    <p style={{fontWeight:'bold',marginLeft:10}}>India,Bhubaneswar</p>
    Plot No# 1 & 2, Adarsha Vihar Lane 2, 
    Adarsh Vihar, Aryapalli, Patia,
    Bhubaneswar, Odisha 751024, India
    </div>

    </div>
    </div>
    )
  };
  
  
  export default Contact;